import { BehaviorSubject } from 'rxjs';

import CONSTANT from '../store/constant';
import { handleResponse } from '../helpers/apiHelper';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    register,
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    set currentUserValue(user) { localStorage.setItem('currentUser', JSON.stringify(user)); }
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'account': { 'email': username, 'password': password } })
    };

    return fetch(`${CONSTANT.apiUrl}/account/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.account));
            currentUserSubject.next(user.account);
            return user;
        });
}

function register(name, username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'account': { 'name': name, 'email': username, 'password': password } })
    };

    return fetch(`${CONSTANT.apiUrl}/account/register`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.account));
            currentUserSubject.next(user.account);
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}