import React from 'react';
import { createBrowserHistory } from 'history';
import { authenticationService } from '../services/authentication.service';
import { Route, Redirect } from 'react-router-dom';

export async function handleResponse(response) {
    if (!response.ok) {

        if (response.status === 401) {
            authenticationService.logout();
            window.location.reload(true);
        }

        throw response.statusText;

    } else {

        const data = await response.json();

        if (data.errors) {
            throw data.errors;
        }

        return data;
    }
}

export const history = createBrowserHistory();

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return { Authorization: `Token ${currentUser.token}` };
    } else {
        return {};
    }
}

export function currentUser() {
    return authenticationService.currentUserValue;
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)
