import React from 'react';

const Register = React.lazy(() => import('./Virun/Auth/register'));
const Login = React.lazy(() => import('./Virun/Auth/login'));
const ChangePassword = React.lazy(() => import('./Virun/Account/password'));
const Profile = React.lazy(() => import('./Virun/Account/profile'));

const route = [
    { path: '/auth/register', exact: true, name: 'Register', component: Register },
    { path: '/auth/login', exact: true, name: 'Login', component: Login },
    { path: '/account/changePassword', exact: true, name: 'Change Password', component: ChangePassword},
    { path: '/account/profile', exact: true, name: 'Profile', component: Profile}
];

export default route;